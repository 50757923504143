
export default {
  name: "Home",
  data(){
    return {
      index_repositories: [
        ["北京市公共数据开放平台", "https://data.beijing.gov.cn/"],
        ["上海市公共数据开放平台", "https://data.sh.gov.cn/"],
        ["山东公共数据开放网", "http://data.sd.gov.cn/"],
        ["“开放广东”政府数据统一开放平台", "https://gddata.gd.gov.cn/"],
        ["贵州省政府数据开放平台", "http://data.guizhou.gov.cn/"],
        ["四川公共数据开放网", "http://www.scdata.gov.cn/"],
        ["广西壮族自治区公共数据开放平台", "http://data.gxzf.gov.cn/"],
        ["福建省公共信息资源统一开放平台", "https://data.fujian.gov.cn/"],
        ["湖南政务大数据公众门户", "http://data.hunan.gov.cn/etongframework-web/main.do"],
        ["河南省公共数据开放平台", "http://data.hnzwfw.gov.cn/odweb/"],
        ["海南省政府数据统一开放平台", "http://data.hainan.gov.cn/"],
        ["宁夏公共数据开放平台", "http://opendata.nx.gov.cn/portal/index"],
        ["天津市信息资源统一开放平台", "https://data.tj.gov.cn/"],
        ["武汉市公共数据开放平台", "https://data.wuhan.gov.cn/"],
        ["重庆市公共数据开放系统", "https://data.cq.gov.cn/"],
        ["浙江·数据开放", "http://data.zjzwfw.gov.cn/"],
        ["河北省公共数据开放网", "http://hebdata.hebyun.gov.cn/home"],
        ["陕西省公共数据开放平台", "http://www.sndata.gov.cn/"],
        ["江西省政府数据开放网站", "https://data.jiangxi.gov.cn/"],
        ["北京大学开放研究数据平台", "https://opendata.pku.edu.cn/"],
        ["复旦大学社会科学数据平台", "https://dvn.fudan.edu.cn/"],
        ["图书馆杂志数据管理平台", "http://data.libraryjournal.com.cn/home/"],
        ["科学数据银行", "https://www.scidb.cn/"],
        ["中国学术调查数据资料库", "http://cnsda.ruc.edu.cn/"],
        ["华东师范大学人文社科大数据平台", "http://sdsp.ecnu.edu.cn/data/"],
        ["中国家庭金融调查与研究中心数据库", "https://chfs.swufe.edu.cn/sjzx/sjsq.htm"],
        ["中国家庭收入调查（CHIP）数据库", "http://www.ciidbnu.org/chip/index.asp"],
        ["香港中文大学研究数据仓储", "https://researchdata.cuhk.edu.hk/"],
        ["香港科技大学数据空间", "https://dataspace.ust.hk/"],
        ["Harvard Dataverse", "https://dataverse.harvard.edu/"],
        ["UK Data Service", "https://ukdataservice.ac.uk/"],
        ["ICPSR", "https://www.icpsr.umich.edu/"],
        ["openICPSR", "https://www.openicpsr.org/"],
        ["Archaeology Data Service", "https://archaeologydataservice.ac.uk/"],
        ["the Digital Archaeological Record", "https://core.tdar.org/"],
        ["da|ra", "https://www.da-ra.de/"],
        ["Research Data Australia", "https://researchdata.edu.au"],
        ["CESSDA", "https://www.cessda.eu/"],
        ["Figshare", "https://figshare.com/"],
        ["International Crops Research Institute for the Semi-Arid Tropics (ICRISAT)", "http://dataverse.icrisat.org/"],
        ["Center for International Forestry Research (CIFOR)", "https://data.cifor.org/"],
        ["Repositori Ilmiah Nasional (RIN)", "https://data.lipi.go.id/"],
        ["Arab Council for the Social Sciences (ACSS) Dataverse", "https://dataverse.theacss.org/"],
        ["MELDATA", "https://data.mel.cgiar.org/"],
        ["DR-NTU (Data)", "https://researchdata.ntu.edu.sg/"],
        ["NIE Data Repository", "https://researchdata.nie.edu.sg/"],
        ["Yale-NUS Dataverse", "https://dataverse.yale-nus.edu.sg/"],
        ["Australian Data Archive (ADA) Dataverse", "https://dataverse.ada.edu.au/"],
        ["Austrian Social Science Data Archive (AUSSDA)", "https://data.aussda.at/"],
        ["Open Data @ UCLouvain", "https://dataverse.uclouvain.be/"],
        ["Social Sciences and Digital Humanities Archive (SODHA)", "https://www.sodha.be/"],
        ["Croatian Social Science Data Archive (CROSSDA)", "https://data.crossda.hr/"],
        ["CIRAD Dataverse", "https://dataverse.cirad.fr/"],
        ["Data INRAe", "https://data.inrae.fr/"],
        ["Data Suds", "https://dataverse.ird.fr/"],
        ["Research data repository of Sciences Po", "https://data.sciencespo.fr/"],
        ["DOREL", "https://dorel.univ-lorraine.fr/"],
        ["Ifsttar Dataverse", "https://research-data.ifsttar.fr/"],
        ["Data Repository of the University of Stuttgart (DaRUS)", "https://darus.uni-stuttgart.de/"],
        ["Göttingen Research Online / Data", "https://data.goettingen-research-online.de/"],
        ["Heidelberg University Open Research Data (HeiDATA)", "https://heidata.uni-heidelberg.de/"],
        ["Jülich DATA", "https://data.fz-juelich.de/"],
        ["Italian Institute of Technology (IIT) Dataverse", "https://dataverse.iit.it/"],
        ["Università degli Studi di Milano (UNIMI) Dataverse", "https://dataverse.unimi.it/"],
        ["Riga Stradins University Research Data Repository", "https://dataverse.rsu.lv/"],
        ["Lithuanian Data Archive for Social Sciences and Humanities (LiDA)", "https://lida.dataverse.lt/"],
        ["DataverseNL", "https://dataverse.nl/"],
        ["International Institute of Social History (IISH) Data Collection", "https://datasets.iisg.amsterdam/"],
        ["NIOZ Dataverse", "https://dataverse.nioz.nl/"],
        ["DataverseNO", "https://dataverse.no/"],
        ["World Agroforestry - Research Data Repository", "https://data.worldagroforestry.org/"],
        ["Open Forest Data", "https://dataverse.openforestdata.pl/"],
        ["Repositório de Dados da Universidade do Minho (DataRepositoriUM)", "https://datarepositorium.uminho.pt/"],
        ["Institute of Russian Literature Dataverse", "https://dataverse.pushdom.ru/"],
        ["CORA. Research Data Repository (RDR)", "https://dataverse.csuc.cat/"],
        ["Repositorio de Datos del Consorcio Madroño (e-cienciaDatos)", "https://edatos.consorciomadrono.es/"],
        ["Abacus Data Network", "https://abacus.library.ubc.ca/"],
        ["Scholars Portal Dataverse", "https://dataverse.scholarsportal.info/"],
        ["UNB Libraries Dataverse", "https://dataverse.lib.unb.ca/"],
        ["University of Manitoba Dataverse", "https://dataverse.lib.umanitoba.ca/"],
        ["CIMMYT Research Data & Software Repository Network", "https://data.cimmyt.org/"],
        ["ASU Library Research Data Repository", "https://dataverse.asu.edu/"],
        ["Dartmouth Dataverse", "https://dataverse.dartmouth.edu/"],
        ["Florida International University Research Data Portal", "https://dataverse.fiu.edu/"],
        ["George Mason University Dataverse", "https://dataverse.orc.gmu.edu/"],
        ["IFDC Dataverse", "https://dataverse.ifdc.org/"],
        ["Johns Hopkins University Data Archive (JHU Data Archive)", "https://archive.data.jhu.edu/"],
        ["University of Virginia Dataverse", "https://dataverse.lib.virginia.edu/"],
        ["Maine Dataverse Network", "http://dataverse.acg.maine.edu/"],
        ["Qualitative Data Repository", "https://data.qdr.syr.edu/"],
        ["Texas Data Repository", "https://dataverse.tdl.org/"],
        ["UCLA Dataverse", "https://dataverse.ucla.edu/"],
        ["UNC Dataverse", "https://dataverse.unc.edu/"],
        ["Virginia Tech Transportation Institute (VTTI) Dataverse", "https://dataverse.vtti.vt.edu/"],
        ["Dataverse do Centro de Integração de Dados e Conhecimentos para Saúde (CIDACS)", "https://dataverse.intracidacs.org/"],
        ["Repositório de Dados de Pesquisa UNIFESP Dataverse", "https://repositoriodedados.unifesp.br/"],
        ["Repositório de Dados de Pesquisa da UFABC", "https://dataverse.ufabc.edu.br/"],
        ["Fundação Oswaldo Cruz Dataverse", "https://dadosdepesquisa.fiocruz.br/"],
        ["Repositorios da Rede Nacional de Ensino e Pesquisa", "https://dadosabertos.rnp.br/"],
        ["Repositorio de datos de investigación de la Universidad de Chile", "https://datos.uchile.cl/"],
        ["Repositorio de Datos de Investigación Universidad del Rosario", "https://research-data.urosario.edu.co/"],
        ["Repositorio de datos de investigación de CEDIA", "https://datos.cedia.edu.ec/"],
        ["International Potato Center (CIP) Dataverse", "https://data.cipotato.org/"],
        ["Pontificia Universidad Católica del Perú Dataverse", "https://datos.pucp.edu.pe/"],
      ]
    }
  },
  methods: {
    search() {
      let query = document.getElementById("s_input").value;
      this.$router.push({name:'search',params:{q:query}});
    }
  }
}
